.slideshow {
    position: relative;
    overflow: hidden;
}

.slides {
    display: flex;
    transition: 1s ease-in-out;
}

.slide {
    flex: 1 0 100%;
}

.slide > img {
    width: 80%;
}

.slide-image {
    border: 1px solid #333;
    border-radius: 20px;
}

.caption {
    text-align: center;
}
